// @flow
import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import configureStore from './createStore';
import App from './AppContainer';
import * as serviceWorker from './serviceWorker';

import './index.scss';

const voidFn = () => {};

if (process.env.NODE_ENV !== 'development') {
  // $FlowFixMe
  console.log = voidFn;
  // $FlowFixMe
  console.warn = voidFn;
  // $FlowFixMe
  console.error = voidFn;
}

const store = configureStore({});
const persistor = persistStore(store);

const rootEl = document.getElementById('root');

if (rootEl) {
  const runApp = rootEl.hasChildNodes() ? hydrate : render;

  runApp(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    rootEl
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
