// @flow
import React from 'react';
import cx from 'classnames';

import styles from './Pagination.module.scss';

type Props = {
  className: string,
  totalPages: number,
  currentPage: number,
  onChange: (page: number) => void,
};

const Pagination = (props: Props) => {
  const switchToPage = (page: number) => {
    if (page > -1 && page <= props.totalPages - 1) {
      props.onChange(page);
    }
  };

  return (
    <ul className={cx(styles.pagination, props.className)}>
      <li
        className={cx(props.currentPage === 0 && styles.disabled)}
        onClick={() => switchToPage(props.currentPage - 1)}
      >
        Prev
      </li>
      {Array.from({ length: props.totalPages }).map((_, index) => (
        <li
          key={index}
          className={cx(props.currentPage === index && styles.current)}
          onClick={() => switchToPage(index)}
        >
          {index + 1}
        </li>
      ))}
      <li
        className={cx(
          props.currentPage === props.totalPages - 1 && styles.disabled
        )}
        onClick={() => switchToPage(props.currentPage + 1)}
      >
        Next
      </li>
    </ul>
  );
};

export default Pagination;
