// @flow
import { createStore as reduxCreateStore, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './redux';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['ui'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const windowExist = typeof window === 'object';

const createStore = (preloadedState: Object) => {
  return reduxCreateStore(
    persistedReducer,
    preloadedState,
    compose(
      windowExist && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (x) => x
    )
  );
};

export default createStore;
