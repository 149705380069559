// @flow
import React from 'react';

import buttonStyle from '../Base/Button.module.scss';
import styles from './SearchForm.module.scss';

type Props = {
  value: string,
  onChange: (value: string) => void,
};

const SearchItems = (props: Props) => {
  return (
    <div className={styles['search-items']}>
      <input
        type="text"
        placeholder="Search multiple order numbers separated by a space. Maximum of 5 order numbers."
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
      />
      <input
        type="submit"
        value="Search"
        className={`${buttonStyle.primary} ${styles.submit}`}
        disabled={!props.value.trim()}
      />
    </div>
  );
};

export default SearchItems;
