// @flow
import axios from 'axios';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { deleteSession } from '../redux/auth';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

const http = axios.create({
  baseURL: `https://apis.paidy${isProd ? '' : '-staging'}.com/apple/dashboard`,
  'Content-Type': 'application/json',
});

http.defaults.headers.common['Paidy-Version'] = '2021-06-14';

const useHttp = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      http.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete http.defaults.headers.common.Authorization;
    }
  }, [token]);

  const handleError = (error) => {
    // If it's a 401, then the token must have expired
    // In that case, log the user out
    if (error.response.status === 401) {
      dispatch(deleteSession());
    }
    return Promise.reject(error);
  };

  return {
    get(...args) {
      return http.get(...args).catch(handleError);
    },
    post(...args) {
      return http.post(...args).catch(handleError);
    },
    patch(...args) {
      return http.patch(...args).catch(handleError);
    },
    delete(...args) {
      return http.delete(...args).catch(handleError);
    },
  };
};

export default useHttp;
