import React from 'react';
import { useSelector } from 'react-redux';

import MainNav from './Nav';
import Session from './Session';

import styles from './Header.module.scss';

const LoggedIn = () => (
  <>
    <MainNav />
    <Session />
  </>
);

const Header = () => {
  const isLoggedIn = useSelector((state) => !!state.auth.token);

  return (
    <div id={styles.header}>
      <h1>
        <img
          src="/assets/logo-white.svg"
          width="55"
          height="29"
          alt="Paidy Merchant Dashboard"
        />
      </h1>
      {isLoggedIn && <LoggedIn />}
    </div>
  );
};

export default Header;
