// @flow
import React, { MouseEvent, useEffect, useState } from 'react';

import styles from './SearchForm.module.scss';

import DateRange from './DateRange';
import SearchItems from './SearchItems';
import ResultFilter from './ResultFilter';

type Props = {
  onSearch: (criteria: any) => void | Promise<void>,
};

const SearchForm = (props: Props) => {
  const [range, setRange] = useState('all');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterKeyword, setFilterKeyword] = useState('');
  const [items, setItems] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const currentURL = new URL(document.location.toString());
  const search = new URLSearchParams(currentURL.search);

  useEffect(() => {
    // Prepopulate the fields with the values parsed from the search parameters
    const parsedItems = search.get('items') || '';
    const parsedRange = search.get('range') || '';
    const parsedFrom = search.get('from');
    const parsedTo = search.get('to');
    const parsedFilterBy = search.get('filterby') || '';
    const parsedFilterKeyword = search.get('filterkeyword') || '';

    setItems(parsedItems);

    setRange(parsedRange || range);
    if (parsedRange === 'custom') {
      setFrom(parsedFrom || from);
      setTo(parsedTo || to);
    }

    setFilterBy(parsedFilterBy);
    setFilterKeyword(parsedFilterKeyword);

    // We want to perform the search later in the queue, when `from` and `to` has the correct values
    // If there are no items, don't perform a search
    if (parsedItems.trim()) {
      setTimeout(() => setIsSearching(true));
    }
  }, []);

  const onSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    // Reflect the new values in the URL
    search.set('range', range);

    // Items
    if (items.trim()) {
      search.set('items', items);
    } else {
      search.delete('items');
    }

    // Date Range
    if (range !== 'custom') {
      search.delete('from');
      search.delete('to');
    } else {
      search.set('from', from);
      search.set('to', to);
    }

    // Filter
    if (filterBy && filterKeyword.trim()) {
      search.set('filterby', filterBy);
      search.set('filterkeyword', filterKeyword.trim());
    } else {
      search.delete('filterby');
      search.delete('filterkeyword');
    }

    window.history.replaceState(
      {},
      '',
      `${document.location.pathname}?${search}`
    );

    // If there are no items, don't perform a search
    if (items.trim()) {
      setIsSearching(true);
    }
  };

  useEffect(() => {
    if (isSearching) {
      if (range === 'custom' && (!from || !to)) {
        return;
      }

      props.onSearch({
        // item1 item2 item3 => item1,item2,item3
        items: items.trim().split(' ').filter(Boolean).join(','),
        // convert to timestamp for backend to consume
        // new Date('YYYY/MM/DD') is the officially supported format by Date so we need to change - to /
        from: new Date(`${from.replace(/-/g, '/')} 00:00:00`).getTime(),
        to: new Date(`${to.replace(/-/g, '/')} 23:59:59`).getTime(),
        filterBy,
        // So that character casing won't matter
        filterKeyword: filterKeyword
          .trim()
          .toLowerCase()
          .split(' ')
          .filter(Boolean),
      });

      setIsSearching(false);
    }
  }, [isSearching]);

  return (
    <form onSubmit={onSubmit} className={styles['search-form']}>
      <SearchItems value={items} onChange={(items) => setItems(items)} />
      <ResultFilter
        value={{ by: filterBy, keyword: filterKeyword }}
        onChange={({ by, keyword }) => {
          setFilterBy(by);
          setFilterKeyword(keyword);
        }}
      />
      <DateRange
        value={{
          range,
          from,
          to,
        }}
        onChange={({ range, to, from }) => {
          setRange(range);
          setFrom(from);
          setTo(to);
        }}
      />
    </form>
  );
};

export default SearchForm;
