// @flow
import React, { ReactNode } from 'react';

import styles from './PageTitleBar.module.scss';

const PageTitleBar = (props: { children: ReactNode }) => {
  return (
    <div className={styles['page-title-bar']}>
      <div className={styles.container}>{props.children}</div>
    </div>
  );
};

export default PageTitleBar;
