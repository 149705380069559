// @flow
import React, { ReactNode } from 'react';
import cx from 'classnames';

type Props = {
  children: ReactNode,
  className?: string[],
  id?: string,
};

import styles from './Table.module.scss';

const Table = (props: Props) => {
  return (
    <table id={props.id} className={cx(styles.table, props.className)}>
      {props.children}
    </table>
  );
};

export default Table;
