// @flow
import React, { ChangeEvent } from 'react';

import styles from './DatePicker.module.scss';

type Props = {
  value: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  disabled?: boolean,
  min?: string,
  max?: string,
  name?: string,
};

const COLOR_DEFAULT = '#6d6d6d';
const COLOR_DISABLED = '#ccced3';

const DatePicker = (props: Props) => {
  return (
    <div className={styles.datepicker}>
      <input
        type="date"
        className={styles['calendar-input']}
        value={props.value}
        min={props.min}
        max={props.max}
        onChange={props.onChange}
        disabled={props.disabled}
        placeholder="Year/Month/Date"
        required
      />
      <input
        type="text"
        name={props.name}
        defaultValue={props.value.replace(/-/g, '/')}
        readOnly
        disabled={props.disabled}
        placeholder="Year/Month/Date"
        required
      />
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        className={styles.chevron}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 1.41421L1.41421 0L4.94974 3.53552L8.48526 0L9.89947 1.41421L6.36395 4.94974L6.36396 4.94975L4.94975 6.36396L4.94974 6.36395L4.94972 6.36396L3.53551 4.94975L3.53552 4.94974L0 1.41421Z"
          fill={props.disabled ? COLOR_DISABLED : COLOR_DEFAULT}
        />
      </svg>
      <svg
        width="22"
        height="34"
        viewBox="0 0 22 34"
        fill="none"
        className={styles.calendar}
      >
        <path
          d="M19 10H4V25H19V10Z"
          stroke={props.disabled ? COLOR_DISABLED : COLOR_DEFAULT}
          strokeWidth="2"
        />
        <path
          d="M8 8V12"
          stroke={props.disabled ? COLOR_DISABLED : COLOR_DEFAULT}
          strokeWidth="2"
        />
        <path
          d="M15 8V12"
          stroke={props.disabled ? COLOR_DISABLED : COLOR_DEFAULT}
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};

export default DatePicker;
