// @flow
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Header from './components/Header/Header';
import NotFound from './components/NotFound/NotFound';
import LoginPage from './components/LoginPage/LoginPage';
import Loading from './components/Loading/Loading';
import TransactionsPage from './components/TransactionsPage/TransactionsPage';
import SettingsPage from './components/SettingsPage/SettingsPage';

import styles from './App.module.scss';

const App = () => {
  const isLoggedIn = useSelector((state) => !!state.auth.token);

  return (
    <BrowserRouter>
      <Header />
      <div className={styles.root}>
        <main className={styles.main}>
          {isLoggedIn ? (
            <Switch>
              <Redirect from="/" to="/transactions" exact />
              <Redirect from="/login" to="/transactions" exact />
              <Route path="/transactions" component={TransactionsPage} />
              <Route path="/settings" component={SettingsPage} />
              <Route path="/*" component={NotFound} />
            </Switch>
          ) : (
            <Switch>
              <Redirect from="/" to="/login" exact />
              <Route path="/login" component={LoginPage} />
              <Route
                path="/transactions"
                component={(routerProps) => (
                  <LoginPage {...routerProps} isSessionExpired={true} />
                )}
              />
              <Route path="/*" component={NotFound} />
            </Switch>
          )}
        </main>
      </div>
    </BrowserRouter>
  );
};

export default () => (
  <Suspense fallback={<Loading />}>
    <App />
  </Suspense>
);
