// @flow
import React, { useState } from 'react';
import cx from 'classnames';

import useHttp from '../../hooks/useHttp';
import { currencyFormatter, formatToLocaleDateTime } from '../../utils';

import Badge from '../Badge/Badge';
import Main from '../Main/Main';
import PageTitleBar from '../PageTitleBar/PageTitleBar';
import Pill from '../Pill/Pill';
import SearchForm from '../SearchForm/SearchForm';
import Table from '../Table/Table';
import Loading from '../Loading/Loading';
import ErrorResult from '../NoResult/ErrorResult';
import LimitResult from '../NoResult/LimitResult';
import NoResult from '../NoResult/NoResult';
import Pagination from '../Pagination/Pagination';

import styles from './TransactionsPage.module.scss';

const STATE_BADGE_COLOR = {
  authorized: 'info',
  initiated: 'info',
  pending: 'info',
  pending_fraud: 'info',
  blocked: 'info',
  unblocked: 'info',
  partial_payoff: 'info',
  full_payoff: 'success',
  closed: 'success',
  confirmed: 'success',
  active: 'success',
  cancelled: 'success',
  refund: 'success',
  rejected: 'danger',
  declined: 'danger',
  withdrawn: 'danger',
  declined_fraud: 'danger',
  declined_insufficient: 'danger',
  declined_application: 'danger',
};

const TYPE_PILL_COLOR = {
  sale: 'info',
  refund: 'info',
  'carrier clawback': 'danger',
  'trade-in clawback': 'danger',
};

const TransactionsPage = () => {
  const http = useHttp();

  const [isEmpty, setIsEmpty] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [transactionGroups, setTransactionGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const onSearch = async ({ items, from, to, filterBy, filterKeyword }) => {
    setTransactionGroups([]);
    setIsEmpty(false);
    setIsError(false);
    setIsLimit(false);
    setCurrentPage(0);

    const fromDateUnixTimestamp = new Date(from).valueOf();
    const toDateUnixTimestamp = new Date(to).valueOf();

    if (items.split(',').length > 5) {
      setIsLimit(true);
    } else {
      setIsLoading(true);
      try {
        const { data } = await http.get(
          `transactions?order_numbers=${items.replace(/,/g, '&order_numbers=')}`
        );

        if (data.transactions.length) {
          // Apply the filters
          const filteredResults = data.transactions
            .map((transactionGroup) => {
              // Filter out items that does not match the filter criteria
              return {
                items: (() => {
                  let results = transactionGroup.transaction_items.filter(
                    (item) => {
                      // Apply filter by Transaction Type/Status
                      if (filterBy) {
                        // filterKeyword is an array of keywords
                        // We check if every keyword in filterKeyword is in the item[filterBy]
                        // .toLowerCase() makes the matching case-insensitive
                        return filterKeyword.every((keyword) => {
                          return item[filterBy]
                            .toLowerCase()
                            .includes(keyword.toLowerCase());
                        });
                      }

                      return true;
                    }
                  );

                  if (from && to) {
                    return results.filter((item) => {
                      // Apply filter by date range
                      const itemCreatedAtUnixTimestamp = new Date(
                        item.created_at
                      ).valueOf();
                      return (
                        itemCreatedAtUnixTimestamp >= fromDateUnixTimestamp &&
                        itemCreatedAtUnixTimestamp < toDateUnixTimestamp
                      );
                    });
                  }

                  return results;
                })(),
                id: transactionGroup.id,
              };
            })
            // Remove transaction groups without any items after filtering
            .filter((transactionGroup) => transactionGroup.items.length);

          // After applying the filters, we want to know how many got through
          // So that we can correctly display either the table or <NoResult />
          if (filteredResults.length) {
            setTransactionGroups(filteredResults);
          } else {
            setIsEmpty(true);
          }
        } else {
          setIsEmpty(true);
        }
      } catch (e) {
        setIsError(true);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitleBar>
        <h2>Transactions</h2>
        <SearchForm onSearch={onSearch} />
      </PageTitleBar>
      <Main>
        {isLoading && <Loading />}
        {isError && <ErrorResult />}
        {isLimit && <LimitResult />}
        {isEmpty && <NoResult />}
        {!isError && !isEmpty && !!transactionGroups.length && (
          <>
            <Pagination
              className={styles.pagination}
              currentPage={currentPage}
              totalPages={Math.ceil(transactionGroups.length / 20)}
              onChange={(page) => setCurrentPage(page)}
            />
            <Table className={styles.table}>
              <thead>
                <tr>
                  <th className={cx(styles['indicator'])}></th>
                  <th className={styles['transaction-id']}>Transaction ID</th>
                  <th className={styles['won']}>WON/ARN</th>
                  <th className={cx(styles['amount'], styles['column-right'])}>
                    Amount
                  </th>
                  <th
                    className={cx(
                      styles['installment'],
                      styles['column-right']
                    )}
                  >
                    Installment
                  </th>
                  <th className={styles['status']}>Type/Status</th>
                </tr>
              </thead>
              <tbody>
                {transactionGroups
                  .slice(currentPage * 20, currentPage * 20 + 20)
                  .map((transactionGroup) =>
                    /** Rendering the table like this:
                     * transactionGroup
                     *   transactionItem
                     *   transactionItem
                     * transactionGroup
                     *   transactionItem
                     *   transactionItem
                     *   transactionItem
                     */
                    transactionGroup.items.map((transactionItem, index) => (
                      <tr key={transactionItem.item_id}>
                        <td
                          className={cx(
                            styles['indicator'],
                            transactionGroup.items.length > 1 && styles['item']
                          )}
                        >
                          {transactionGroup.items.length > 1 && !index && (
                            // Display only if there are multiple transactions in the group
                            <svg width="16" height="11" viewBox="0 0 16 11">
                              <path
                                d="M7.22855 10.0647C7.62853 10.5496 8.37147 10.5496 8.77145 10.0647L15.3107 2.13628C15.8486 1.48405 15.3847 0.5 14.5392 0.5H1.46079C0.615317 0.5 0.151372 1.48404 0.68933 2.13628L7.22855 10.0647Z"
                                fill="currentColor"
                              />
                            </svg>
                          )}
                        </td>
                        <td className={styles['transaction-id']}>
                          {!index && (
                            <>
                              <b>{transactionGroup.id}</b>
                              <span>
                                {formatToLocaleDateTime(
                                  new Date(transactionItem.created_at)
                                )}
                              </span>
                            </>
                          )}
                        </td>
                        <td>{transactionItem.order_number}</td>
                        <td className={cx(styles['column-right'])}>
                          <b>
                            {currencyFormatter.format(transactionItem.amount)}
                          </b>
                        </td>
                        <td className={cx(styles['column-right'])}>
                          <b>{transactionItem.duration || 1}</b>
                        </td>
                        <td>
                          <Pill
                            type={
                              TYPE_PILL_COLOR[
                                transactionItem.type.toLowerCase()
                              ]
                            }
                          >
                            {transactionItem.type}
                          </Pill>
                          <br />
                          <Badge
                            type={
                              STATE_BADGE_COLOR[
                                transactionItem.state.toLowerCase()
                              ]
                            }
                          >
                            {transactionItem.state}
                          </Badge>
                        </td>
                      </tr>
                    ))
                  )}
              </tbody>
            </Table>
            <Pagination
              className={styles.pagination}
              currentPage={currentPage}
              totalPages={Math.ceil(transactionGroups.length / 20)}
              onChange={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </Main>
    </>
  );
};

export default TransactionsPage;
