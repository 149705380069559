// @flow
import React, { ReactNode } from 'react';
import cx from 'classnames';

import styles from './Pill.module.scss';

type Props = {
  children?: ReactNode,
  type?: 'info' | 'danger',
};

const Pill = (props: Props) => {
  return (
    <span
      className={cx(styles.pill, props.type ? styles[props.type] : styles.info)}
    >
      {props.children}
    </span>
  );
};

export default Pill;
