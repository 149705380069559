// @flow
import React, { ReactNode } from 'react';
import cx from 'classnames';

import styles from './Badge.module.scss';

type Props = {
  children?: ReactNode,
  type?: 'info' | 'success' | 'danger',
};

const Badge = (props: Props) => {
  return (
    <span
      className={cx(
        styles.badge,
        props.type ? styles[props.type] : styles.info
      )}
    >
      <span className={cx(styles.circle)} />
      {props.children}
    </span>
  );
};

export default Badge;
