// @flow
const CREATE_SESSION = 'session/CREATE';
const DELETE_SESSION = 'session/DELETE';

const initialState = {
  email: '',
  token: '',
  permissions: [],
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CREATE_SESSION:
      const data = action.data;

      return {
        ...state,
        ...data,
      };
    case DELETE_SESSION:
      return {
        ...state,
        email: '',
        token: '',
      };
    default:
      return state;
  }
};

const createSession = (data) => ({
  type: CREATE_SESSION,
  data,
});

const deleteSession = () => ({
  type: DELETE_SESSION,
});

export { createSession, deleteSession };
export default reducer;
