// @flow
import React from 'react';

import styles from './NoResult.module.scss';

const NoResult = () => (
  <article className={styles['no-result']}>
    <h4>Maximum limit exceeded</h4>
    <p>Please limit the number of search items to 5.</p>
  </article>
);

export default NoResult;
