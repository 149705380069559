// @flow
import React from 'react';

import styles from './SearchForm.module.scss';

type FilterProps = {
  by: '' | 'type' | 'state' | 'order_number',
  keyword: string,
};

type Props = {
  value: FilterProps,
  onChange: (value: FilterProps) => void,
};

const PLACEHOLDER_MESSAGE = {
  type: 'Select Transaction Type',
  state: 'Select Status Type',
};

const CHOICES = {
  type: ['SALE', 'CARRIER CLAWBACK', 'TRADE-IN CLAWBACK'],
  state: [
    'AUTHORIZED',
    'INITIATED',
    'PENDING',
    'PENDING_FRAUD',
    'BLOCKED',
    'UNBLOCKED',
    'CLOSED',
    'CONFIRMED',
    'ACTIVE',
    'CANCELLED',
    'REJECTED',
    'DECLINED',
    'WITHDRAWN',
    'DECLINED_FRAUD',
    'DECLINED_INSUFFICIENT',
    'DECLINED_APPLICATION',
  ],
};

const ResultFilter = (props: Props) => {
  const applyChange = (value: Partial<FilterProps>) => {
    // If a new filter type is selected, clear the old keyword
    if (value.by !== undefined && value.by !== props.value.by) {
      value.keyword = '';
    }

    props.onChange({
      ...props.value,
      ...value,
    });
  };

  return (
    <>
      <div className={styles['result-filter']}>
        <div className={styles['select-container']}>
          <select
            value={props.value.by}
            onChange={({ target }) => applyChange({ by: target.value })}
          >
            <option disabled>Filter Search Results</option>
            <option value="">None</option>
            <option value="type">Transaction Type</option>
            <option value="state">Status</option>
          </select>
          <svg width="14" height="11" viewBox="0 0 14 11" fill="none">
            <path
              d="M7 11L0.937822 0.5L13.0622 0.499999L7 11Z"
              fill="#4C4C4C"
            />
          </svg>
        </div>
        {props.value.by && props.value.by !== 'order_number' && (
          <div className={styles['select-container']}>
            <select
              value={props.value.keyword}
              onChange={({ target }) => applyChange({ keyword: target.value })}
            >
              <option value="" disabled>
                {PLACEHOLDER_MESSAGE[props.value.by]}
              </option>
              {CHOICES[props.value.by].map((choice) => (
                <option key={choice} value={choice}>
                  {choice}
                </option>
              ))}
            </select>
            <svg width="14" height="11" viewBox="0 0 14 11" fill="none">
              <path
                d="M7 11L0.937822 0.5L13.0622 0.499999L7 11Z"
                fill="#4C4C4C"
              />
            </svg>
          </div>
        )}
      </div>
    </>
  );
};

export default ResultFilter;
