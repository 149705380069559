import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useHttp from '../../hooks/useHttp';
import { deleteSession } from '../../redux/auth';
import { validatePassword } from '../../utils';

import buttonStyle from '../Base/Button.module.scss';
import dialogStyle from '../Base/Dialog.module.scss';
import styles from './SettingsPage.module.scss';

const ResetPassword = (props: Object) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const http = useHttp();
  const auth = useSelector((state) => state.auth);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const passwordRef = useRef(null);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    passwordRef.current.focus();
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    setErrors([]);

    const pushErrors = (newErrors: Array) => {
      setErrors((errors) => [...errors, ...newErrors]);
    };

    if (validatePassword(password).length) {
      pushErrors(validatePassword(password));
    } else if (password !== confirmPassword) {
      pushErrors(['Password does not match. Try again.']);
    } else {
      setIsLoading(true);
      setTimeout(async () => {
        try {
          await http.patch('/members/current', { password });
          setIsSuccess(true);

          // Delete the session once password reset is successful
          http.delete(`/sessions/${auth.token}`);

          // If user reloads the page, logout the app
          window.addEventListener('beforeunload', () =>
            dispatch(deleteSession())
          );
        } catch (error) {}
      }, 500);
    }
  };

  const logout = () => {
    dispatch(deleteSession());
    history.push('/');
  };

  return (
    <div className={dialogStyle.root}>
      <div className={`${dialogStyle.content} ${styles.dialog}`}>
        {!isSuccess && (
          <form onSubmit={onSubmit}>
            <h3>Reset Password</h3>
            <span>Change your current password to a new password.</span>
            <br />
            <div className={styles['form-group']}>
              <fieldset>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  placeholder="Password"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  autoComplete="new-password"
                  ref={passwordRef}
                />
              </fieldset>
              <fieldset>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                  }}
                  autoComplete="new-password"
                />
              </fieldset>
            </div>
            <div className={styles['error-msg']}>
              {errors.map((message) => (
                <p key={message}>{message}</p>
              ))}
            </div>
            <fieldset>
              <input
                type="submit"
                value="Reset Password"
                className={buttonStyle.primary}
                disabled={isLoading}
              />
            </fieldset>
            <fieldset>
              <button
                className={`${buttonStyle.primary} ${styles['button-cancel']}`}
                onClick={props.onClose}
                disabled={isLoading}
              >
                Cancel
              </button>
            </fieldset>
          </form>
        )}
        {isSuccess && (
          <div className={styles['success-msg']}>
            <br />
            <svg
              width="88"
              height="88"
              viewBox="0 0 88 88"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44 0C68.3005 0 88 19.6995 88 44C88 68.3005 68.3005 88 44 88C19.6995 88 0 68.3005 0 44C0 19.6995 19.6995 0 44 0ZM44 5.07692C22.5034 5.07692 5.07692 22.5034 5.07692 44C5.07692 65.4966 22.5034 82.9231 44 82.9231C65.4966 82.9231 82.9231 65.4966 82.9231 44C82.9231 22.5034 65.4966 5.07692 44 5.07692ZM38.7978 62.1278L66.3764 29.1278C68.457 26.6382 64.9083 23.4084 62.625 25.7136L35.7225 52.8748L25.2635 43.522C22.8868 41.3967 19.5594 44.6982 21.666 47.0915L34.9445 62.1772C35.9726 63.3452 37.8 63.3218 38.7978 62.1278Z"
                fill="#8BC34A"
              />
            </svg>
            <br />
            <br />
            <h3>Successful Password Change</h3>
            <span>
              You can now use your new password to log in to your account.
            </span>
            <br />
            <br />
            <br />
            <button className={buttonStyle.primary} onClick={logout}>
              Relogin
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
