// @flow
export const formatToLocaleDateTime = (date: Date) => {
  return date.toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

// Format date to YYYY-MM-DD
export const formatToISODate = (date: Date) => {
  const dateParts = new Intl.DateTimeFormat('UTC', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatToParts(date);

  const getValue = (objType) =>
    dateParts.find((obj) => obj.type === objType).value;

  return `${getValue('year')}-${getValue('month')}-${getValue('day')}`;
};

export const currencyFormatter = (() => {
  if (Intl !== undefined && Intl.NumberFormat) {
    return new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    });
  }

  return {
    format(input: number) {
      return `¥${input}`;
    },
  };
})();

export const validateEmail = (email: string) => {
  const trimmedEmail = email.trim();

  const errors = [];

  if (!trimmedEmail) {
    errors.push('Enter a valid email.');
  } else {
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        trimmedEmail
      )
    ) {
      errors.push('Email is not valid.');
    }
  }

  return errors;
};

export const validatePassword = (password: string) => {
  const errors = [];

  if (!password) {
    errors.push('Enter a valid password.');
  } else {
    const prefix = 'Password should have at least';

    if (!/.{6}/.test(password)) {
      errors.push(`${prefix} 6 characters.`);
    }
  }

  return errors;
};
