// @flow
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import styles from './Nav.module.scss';

const MainNav = () => (
  <nav className={styles['main-nav']}>
    <NavLink to="/transactions">Transactions</NavLink>
    <NavLink to="/settings">Settings</NavLink>
  </nav>
);

const NavLink = (props: { children?: JSX.Element, to: string }) => {
  const match = useRouteMatch(props.to);

  return (
    <Link to={props.to} className={match && styles['active']}>
      {props.children}
    </Link>
  );
};

export default MainNav;
