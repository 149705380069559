// @flow
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useHttp from '../../hooks/useHttp';
import { deleteSession } from '../../redux/auth';

import styles from './Session.module.scss';

const Session = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const http = useHttp();
  const auth = useSelector((state) => state.auth);

  const logout = () => {
    http.delete(`/sessions/${auth.token}`);
    dispatch(deleteSession());
    history.push('/');
  };

  return (
    <div className={styles['session-panel']}>
      <b>{auth.email}</b>
      <button onClick={logout}>Logout</button>
    </div>
  );
};

export default Session;
