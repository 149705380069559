// @flow
import React, { useEffect } from 'react';
import cx from 'classnames';

import DatePicker from '../DatePicker/DatePicker';

import { formatToISODate } from '../../utils';
import styles from './SearchForm.module.scss';

type DateRangeProps = {
  range: 'all' | 'today' | 'week' | 'month' | 'custom',
  from: string,
  to: string,
};

type Props = {
  value: DateRangeProps,
  onChange: (value: DateRangeProps) => void,
};

const DateRange = (props: Props) => {
  useEffect(() => {
    if (props.value.range) {
      let dateFrom = new Date();
      let dateTo = new Date();

      switch (props.value.range) {
        case 'week': {
          // Results to Monday of the current week
          dateFrom.setDate(dateFrom.getDate() - dateFrom.getDay() + 1);

          // Results to Sunday of the current week
          dateTo.setDate(dateTo.getDate() + (7 - dateTo.getDay()));

          break;
        }
        case 'month': {
          // Results to 1st of the current month
          dateFrom.setDate(1);

          // Results to last day of the current month
          dateTo.setMonth(dateTo.getMonth() + 1);
          dateTo.setDate(0);

          break;
        }
      }

      applyChange({
        from: props.value.range !== 'all' ? formatToISODate(dateFrom) : '',
        to: props.value.range !== 'all' ? formatToISODate(dateTo) : '',
      });
    }
  }, [props.value.range]);

  const applyChange = (value: Partial<DateRangeProps>) => {
    props.onChange({
      ...props.value,
      ...value,
    });
  };

  return (
    <>
      <div className={styles['date-range']}>
        <div className={styles['select-container']}>
          <select
            value={props.value.range}
            onChange={({ target }) => applyChange({ range: target.value })}
          >
            <option disabled>Select Date Range</option>
            <option value="all">All Dates</option>
            <option value="today">Today</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="custom">Range</option>
          </select>
          <svg width="14" height="11" viewBox="0 0 14 11" fill="none">
            <path
              d="M7 11L0.937822 0.5L13.0622 0.499999L7 11Z"
              fill="#4C4C4C"
            />
          </svg>
        </div>
        <b className={cx(props.value.range != 'custom' && styles['disabled'])}>
          Date From:
        </b>
        <DatePicker
          name="date-from"
          value={props.value.from}
          max={props.value.to}
          onChange={({ target }) => applyChange({ from: target.value })}
          disabled={props.value.range !== 'custom'}
        />
        <b className={cx(props.value.range != 'custom' && styles['disabled'])}>
          Date To:
        </b>
        <DatePicker
          name="date-to"
          value={props.value.to}
          min={props.value.from}
          onChange={({ target }) => applyChange({ to: target.value })}
          disabled={props.value.range !== 'custom'}
        />
      </div>
    </>
  );
};

export default DateRange;
