// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteChildrenProps } from 'react-router-dom';

import { createSession } from '../../redux/auth';
import useHttp from '../../hooks/useHttp';

import styles from './LoginPage.module.scss';
import button from '../Base/Button.module.scss';

type Props = RouteChildrenProps<{ isSessionExpired?: boolean }>;

const LoginPage = (props: Props) => {
  const dispatch = useDispatch();
  const http = useHttp();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onLogin = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    // Add a fake loading so that the button enabling/disabling won't look jarring
    setTimeout(async () => {
      try {
        const { data } = await http.post('/sessions', { email, password });
        dispatch(
          createSession({
            email: data.username,
            token: data.id,
            permissions: data.permissions,
          })
        );
      } catch (error) {
        setShowError(true);
      }

      setIsLoading(false);
    }, 500);
  };

  return (
    <>
      <div className={styles['login-root']}>
        <form className={styles['login-form']} onSubmit={onLogin}>
          <h1>
            <img
              src="/assets/logo-color.svg"
              width="118"
              height="62"
              alt="Paidy"
            />
          </h1>

          <p>Merchant Login</p>

          <div className={styles['login-error-msg']}>
            {showError && 'Incorrect email address or password entered.'}
            {props.isSessionExpired &&
              !showError &&
              'Your session has expired. Please log in again.'}
          </div>

          <fieldset>
            <input
              id="email"
              name="email"
              type="text"
              value={email}
              placeholder="Email"
              onChange={(event) => setEmail(event.target.value)}
            />
          </fieldset>
          <fieldset>
            <input
              id="password"
              name="password"
              type="password"
              value={password}
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
              autoComplete="current-password"
            />
          </fieldset>

          <fieldset>
            <input
              type="submit"
              value="Log In"
              className={button.primary}
              disabled={isLoading}
            />
          </fieldset>
        </form>
        <div className={styles.copy}>© Paidy Inc</div>
      </div>
    </>
  );
};

export default LoginPage;
