// @flow
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Main from '../Main/Main';
import PageTitleBar from '../PageTitleBar/PageTitleBar';

import styles from './SettingsPage.module.scss';
import buttonStyle from '../Base/Button.module.scss';

import ResetPassword from './ResetPassword';
import CreateNewUser from './CreateNewUser';

const SettingsPage = () => {
  const permissions = useSelector((state) => state.auth.permissions);

  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState(
    false
  );
  const [isCreateNewUserDialogOpen, setIsCreateNewUserDialogOpen] = useState(
    false
  );

  return (
    <>
      <PageTitleBar>
        <h2>Settings</h2>
      </PageTitleBar>
      <Main className={styles.main}>
        <SettingsTile
          title="Reset password"
          description="Change your current password to a new password."
          buttonTitle="Reset Password"
          onButtonClick={() => setIsResetPasswordDialogOpen(true)}
        />
        {permissions.includes('can_add_member') && (
          <SettingsTile
            title="Create New User"
            description="As an administrator, you can create a new user."
            buttonTitle="Create New User"
            onButtonClick={() => setIsCreateNewUserDialogOpen(true)}
          />
        )}
      </Main>
      {isResetPasswordDialogOpen && (
        <ResetPassword onClose={() => setIsResetPasswordDialogOpen(false)} />
      )}
      {isCreateNewUserDialogOpen && (
        <CreateNewUser onClose={() => setIsCreateNewUserDialogOpen(false)} />
      )}
    </>
  );
};

const SettingsTile = (props: Object) => (
  <div className={styles['settings-tile']}>
    <div>
      <h3>{props.title}</h3>
      <p>{props.description}</p>
    </div>
    <div>
      <button className={buttonStyle.secondary} onClick={props.onButtonClick}>
        {props.buttonTitle}
      </button>
    </div>
  </div>
);

export default SettingsPage;
